<template>
  <LoadingBar v-if="isLoadingA"></LoadingBar>
  <loading :active="isLoadingA" :is-full-page="fullPage" :loader="loader" />
  <div>
    <page-header>
      <page-title title="Change Password">
        <div class="flex flex-row justify-center flex-1">
          <p class="px-10 font-medium text-right x-sm:px-1">
            <button @click="saveDetails" class="focus:outline-none">Save</button>
          </p>
        </div>
      </page-title>
    </page-header>

    <div class="vendor-container outer">
      <div class="vendor-content">
        <div
          class="
            inline-block
            min-w-full
            overflow-hidden
            align-middle
            border-b border-gray-200
            sm:rounded-lg
          "
        ></div>
        <form>
          <div>
            <h1 class="text-left mb-2">CHANGE PASSWORD</h1>
          </div>
          <div
            class="
              px-3
              py-3
              bg-white
              border-b border-gray-200
              mt-
              sm:rounded-lg
            "
          >
            <label class="flex flex-row items-center mt-1">
              <span class="w-1/12 text-left text-gray-700"
                >Current Password:</span
              >
              <input
                name="businessName"
                type="password"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="Current Password"
                v-model="input.password"
                @input="validatePassword"
                @blur="onBlur('password')"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.password"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.password }}
              </p>
            </div>
            <label class="flex flex-row items-center mt-1">
              <span class="w-1/12 text-left text-gray-700">New Password:</span>
              <input
                name="businessName"
                type="password"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="New Password"
                v-model="input.new_password"
                @input="validateNewPassword"
                @blur="onBlur('new_password')"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.new_password"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.new_password }}
              </p>
            </div>
            <label class="flex flex-row items-center mt-1">
              <span class="w-1/12 text-left text-gray-700"
                >Confirm Password:</span
              >
              <input
                name="businessName"
                type="password"
                class="flex-1 block w-full mt-1 ml-2 border-transparent rounded"
                placeholder="Confirm Password"
                v-model="input.confirm_password"
                @input="validateCPassword"
                @blur="onBlur('confirm_password')"
              />
            </label>
            <div
              class="flex flex-row items-center mt-1"
              v-if="!validStatus.confirm_password"
            >
              <span class="w-1/12 text-left text-gray-700"></span>
              <p class="pl-5 text-xs italic text-left text-red-500 errorMsg">
                {{ errors.confirm_password }}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <page-footer>
    <footer-nav></footer-nav>
  </page-footer>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/pages/Header.vue";
import PageTitle from "@/views/Vendor/PageTitle.vue";
import PageFooter from "@/components/pages/Footer.vue";
import FooterNav from "@/views/Vendor/FooterNav.vue";
import LoadingBar from "@/components/loader/Loader.vue";
import ModalView from "@/components/Modal.vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    PageHeader,
    PageTitle,
    PageFooter,
    FooterNav,
    LoadingBar,
    ModalView,
    Loading,
  },
  data() {
    return {
      userId: undefined,
      isLoadingA: false,
      fullPage: true,
      loader: "bars",
      input: {
        password: "",
        new_password: "",
        confirm_password: "",
      },
      blurStatus: {
        password: false,
        new_password: false,
        confirm_password: false,
      },
      errors: {
        password: "",
        new_password: "",
        confirm_password: "",
      },
      validStatus: {
        password: true,
        new_password: true,
        confirm_password: true,
      },
    };
  },
  methods: {
    validatePassword() {
      if (this.blurStatus.password) {
        if (this.input.password) {
          if (this.input.password.length < 6) {
            this.validStatus.password = false;
            this.errors.password = "Password must be at least 6 characters";
          } else {
            this.validStatus.password = true;
            this.errors.password = "";
          }
        } else {
          this.validStatus.password = false;
          this.errors.password = "Please enter current password";
        }
      }
    },
    validateNewPassword() {
      if (this.blurStatus.new_password) {
        if (this.input.new_password) {
          if (this.input.new_password.length < 6) {
            this.validStatus.new_password = false;
            this.errors.new_password = "Password must be at least 6 characters";
          } else {
            this.validStatus.new_password = true;
            this.errors.new_password = "";
            this.validateCPassword();
          }
        } else {
          this.validStatus.new_password = false;
          this.errors.new_password = "Please enter new password";
        }
      }
    },
    validateCPassword() {
      if (this.blurStatus.confirm_password) {
        if (this.input.confirm_password) {
          if (
            this.input.new_password &&
            this.input.new_password != this.input.confirm_password
          ) {
            this.validStatus.confirm_password = false;
            this.errors.confirm_password =
              "Password and confirm password does not match";
          } else {
            this.validStatus.confirm_password = true;
            this.errors.confirm_password = "";
          }
        } else {
          this.validStatus.confirm_password = false;
          this.errors.confirm_password = "Please enter confirm password";
        }
      }
    },
    onBlur(field) {
      switch (field) {
        case "password":
          this.blurStatus.password = true;
          this.validatePassword();
          break;
        case "new_password":
          this.blurStatus.new_password = true;
          this.validateNewPassword();
          break;
        case "confirm_password":
          this.blurStatus.confirm_password = true;
          this.validateCPassword();
          break;
      }
    },
    saveDetails() {
      let arr = ["password", "new_password", "confirm_password"];
      for (let index = 0; index < arr.length; index++) {
        this.onBlur(arr[index]);
      }
      if (
        this.validStatus.password &&
        this.validStatus.new_password &&
        this.validStatus.confirm_password &&
        this.validStatus.new_password == this.validStatus.confirm_password
      ) {
        this.isLoadingA = true;
        let reqData = {
          current_password: this.input.password,
          password: this.input.new_password,
          confirm_password: this.input.confirm_password
        };

        this.$http
          .post("auth/change-password", reqData)
          .then((response) => {
            this.$router.push({ name: "VendorBilling" });
            this.isLoadingA = false;
            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            let errorMessage = "";
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              if (
                Array.isArray(error.response.data.message) &&
                error.response.data.message.length
              ) {
                console.log(Array.isArray(error.response.data.message));
                errorMessage = error.response.data.message[0];
              } else if (typeof error.response.data.message === "string") {
                errorMessage = error.response.data.message;
              } else {
                errorMessage = "Something went wrong.";
              }
            } else {
              errorMessage = "Something went wrong.";
            }
            this.isLoadingA = false;
            this.$swal.fire({
              icon: "error",
              title: errorMessage,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
        console.log(reqData);
      } else {
        console.log("invalid");
      }
    },
  },
  computed: {
    profileData() {
      return this.$store.getters["profile/inputData"];
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("userData"));
    this.userId = user.id;
  },
});
</script>
<style scoped>
input::placeholder {
  color: #2c3e5047;
}
.outer {
  min-height: 60vh;
}
</style>
